<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <template v-if="isEnrolledCounseling">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingClassesDetail',
                params: { id: $route.params.counselingId }
              }"
            >
              <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
              {{ sessionClass.title }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingSchool',
                params: { id: $route.params.counselingId }
              }"
            >
              {{ pageTitle }}
            </router-link>
          </el-breadcrumb-item>
        </template>
        <template v-else>
          <el-breadcrumb-item>
            <router-link :to="{ name: 'counseling' }">
              Counseling
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingUserSchool',
                params: { username: counselingStudent.username }
              }"
            >
              {{ pageTitle }}
            </router-link>
          </el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>
          {{ schoolName }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="title">
      <h1>
        <router-link
          :to="{
            name: 'School',
            params: { schoolId: collectedSchool.school.slug, type: 'overview' }
          }"
        >
          {{ schoolName }}
        </router-link>
      </h1>
    </div>
    <hr class="separate-line" />
    <el-form ref="collectedSchool" label-width="210px">
      <el-form-item prop="type" :label="$t('collectedSchool.form.category')">
        <CollectCollegeTypeTag :type="collectedSchool.type" />
      </el-form-item>
      <el-form-item
        prop="is_applying"
        :label="$t('collectedSchool.form.applying')"
      >
        {{
          `${
            collectedSchool.is_applying
              ? $t("collectedSchool.form.yes")
              : $t("collectedSchool.form.no")
          }`
        }}
      </el-form-item>
      <el-form-item
        v-if="collectedSchool.is_applying === 1"
        prop="application_type"
        :label="$t('collectedSchool.form.applicationType')"
      >
        {{ collectedSchool.application_type }}
      </el-form-item>
      <el-form-item
        prop="application_portal"
        :label="$t('collectedSchool.form.applicationForm')"
      >
        {{ collectedSchool.application_portal }}
      </el-form-item>
      <el-form-item
        prop="application_submission_date"
        :label="$t('collectedSchool.form.applicationSubmissionDate')"
      >
        {{ collectedSchool.application_submission_date }}
      </el-form-item>
      <el-form-item
        prop="financial_submission_date"
        :label="$t('collectedSchool.form.financialAidSubmissionDate')"
      >
        {{ collectedSchool.financial_submission_date }}
      </el-form-item>
      <el-form-item
        prop="login_field_username"
        :label="$t('collectedSchool.form.applicationUsername')"
      >
        {{ collectedSchool.login_field_username }}
      </el-form-item>
      <el-form-item
        prop="login_field_password"
        :label="$t('collectedSchool.form.applicationPassword')"
      >
        {{ loginPassword }}
        <span class="action-icon" @click="visiblePassage = !visiblePassage">
          <span v-if="!visiblePassage" key="eye"><i class="fa fa-eye" /></span>
          <span v-else key="eye-slash"><i class="fas fa-eye-slash" /></span>
        </span>
      </el-form-item>
      <el-form-item
        prop="admissions_result"
        :label="$t('collectedSchool.form.admissionsResult')"
      >
        <span v-if="collectedSchool.admissions_result === 'admitted'">
          {{ $t('collectedSchool.form.admitted') }}
        </span>
        <span v-else-if="collectedSchool.admissions_result === 'rejected'">
          {{ $t('collectedSchool.form.rejected') }}
        </span>
        <span v-else-if="collectedSchool.admissions_result === 'deferred'">
          {{ $t('collectedSchool.form.deferred') }}
        </span>
        <span v-else-if="collectedSchool.admissions_result === 'waitlisted'">
          {{ $t('collectedSchool.form.waitlisted') }}
        </span>
      </el-form-item>
      <el-form-item
        v-if="collectedSchool.admissions_result === 'admitted'"
        prop="is_enrolled"
        :label="$t('collectedSchool.form.enrolled')"
      >
        {{ `${collectedSchool.is_enrolled ? $t("collectedSchool.form.yes") : $t("collectedSchool.form.no")}`  }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CollectCollegeTypeTag from "@/components/school/CollectCollegeTypeTag";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: "Colleges - Ivy-Way Academy"
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    CollectCollegeTypeTag
  },
  computed: {
    pageTitle() {
      if (this.counselingStudent.first_name) {
        return `${this.counselingStudent.first_name || ""}'s Colleges`;
      } else {
        return "";
      }
    },
    schoolName() {
      const schoolNameI18nObject = this.collectedSchool.school.name;
      if (this.lang === "EN-US") {
        return schoolNameI18nObject[this.langList[this.lang]];
      } else {
        return `${schoolNameI18nObject[this.langList[this.lang]]} (${schoolNameI18nObject.en})`;
      }
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    collectedSchool() {
      return this.$store.getters["collectSchool/collectedSchool"];
    },
    loginPassword() {
      if (this.visiblePassage) {
        return this.collectedSchool.login_field_password;
      } else {
        const passwordLen = this.collectedSchool.login_field_password?.length || 0;
        return "".padStart(passwordLen, "●");
      }
    },
    isEnrolledCounseling() {
      return this.$route.name === "counselingCollectedColleges";
    }
  },
  data() {
    return {
      visiblePassage: false,
      counselingStudent: { first_name: "" },
      sessionClass: {
        course_session: {}
      }
    };
  },
  async created() {
    if (this.isEnrolledCounseling) {
      await this.fetchCustomLesson(this.$route.params.counselingId);
    } else {
      await this.fetchUserProfile();
    }
    this.fetchCollectedSchool(this.$route.params.collectedCollegeId);
  },
  methods: {
    async fetchCustomLesson(counselingId) {
      const customLesson = await privateApi.getCustomLesson({
        class_id: counselingId
      });
      this.sessionClass = {
        ...customLesson.session_class
      };
      this.counselingStudentId =
        customLesson.student_class_tickets[0].student.id;
      this.counselingStudent = customLesson.student_class_tickets[0].student;
    },
    async fetchUserProfile() {
      const { basic_info, student: { counseling_schools_type } } = await profileApi.getUserByName(
        this.$route.params.username
      );
      this.counselingStudent = { ...basic_info, counseling_schools_type };
    },
    fetchCollectedSchool(collectedCollegeId) {
      this.$store.dispatch("collectSchool/fetchCollectedSchool", collectedCollegeId);
    }
  }
};
</script>

<style scoped>
.safety {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
}
</style>